.invisible {
    opacity: 0;
}

.visible {

}

.stepper {
    margin-top: 20px;
    padding-bottom: 10px;
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,0.125);
}

.stepper a, .stepper a + p{
    margin: 0;
    min-width: 150px;
}
.stepper a {
    font-weight: 600;
    padding: 0.5rem 1rem;
}
.stepper a + p {
    font-weight: 400;
    border-top: 1px solid #1a1a1a;
    padding: 0.25rem 1rem;
    transform: translateY(-0.25rem);
    white-space: nowrap;
}
.stepper a.active{
    background-color: #1a1a1a;
}
