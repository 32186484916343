@keyframes glowing {
        0% {
          box-shadow: 0 0 5px #b52b27;
        }
        50% {
          box-shadow: 0 0 25px #c9302c;
        }
        100% {
          box-shadow: 0 0 5px #b52b27;
        }
      }
.blink {
  animation: glowing 2000ms infinite;
}
