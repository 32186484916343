.titleText {
    color: white;
    outline: #0c5460;
    text-align: center;
}

.smallFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
}
.smallFlex > img {
    height: 5rem;
}
.smallFlex > h1 {
    margin: 0;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .smallFlex {
        justify-content: center;
    }
    .logobde {
        order: 1;
    }
    .logova {
        order: 2;
    }
    .smallTitle {
        order: 3;
        flex-basis: 100%;
    }
}


.bgparent {
    position: relative;
    height: 100%;
}

.holibg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*height: 100%;*/
    background: url("https://logos.bde-insa-lyon.fr/anim/holiparty.webp"); /* en fait on peut pas utiliser Adobe XD comme CDN ... :( */
    background-repeat: repeat-y;
    background-size: cover;
    background-position-y: -20vh;
    filter: blur(5px);
    z-index: -1;
}

.holifg {
    background: rgba(0, 0, 0, 0.3);
    min-height: 100vh;
}

.frosted {
    background-color: rgba(255, 255, 255, 0.7);
}

.main-landing-box {
    padding: 2rem;
    border-radius: 1rem;
    gap: 1rem;
}

.landing-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.intro-main {
    margin-right: auto;
    margin-left: auto;
    padding: 2rem;
}

.intro-box {
    margin-left: 2rem;
    margin-right: 2rem;
    padding: 2rem;
    border-radius: 1rem;
}

.intro-button-large-container {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    right: 2rem;
    display: block;
    margin: auto;
}

.intro-button-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.intro-button {
    align-self: flex-end;
    border-radius: 0.5rem;
}

.intro-button-btn {
    border-radius: 10rem;
}
.image-fix {
    max-height: 30rem;
    width: 100%;
    object-fit: contain;
}

.bouton-rond {
    border-radius: 1rem;
    font-size: 1.5rem;
}

.juste {
    text-align: justify;
}

.boutonsInscriptionConnexionFlex {
    margin-top: 2rem;
}
@media screen and (min-width: 600px) {
    .main-landing-box {
        padding: 3rem;
        border-radius: 1rem;
        gap: 2rem;
    }
}
