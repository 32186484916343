.easteregg-main {
    top: 0;
    left: 0;
    z-index: 5000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.5;
}

.easteregg-window {
    position: absolute;
    top: 30%;
    left: 20%;
    width: 20rem;
    z-index: 9000;
    letter-spacing: 0;
    line-height: 1;
}

.fieldset-center {
    text-align: center;
}

.fieldset-legend {
    font-size: 1em;
}
