/* joinva.css */

.vacontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 3rem 2rem;
}

.joinva-logos {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 3rem !important;
    margin: 0;
}

.joinva-logos .box {
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    text-align: center;
    padding: 1rem;
    display: flex;
    flex-direction: column; /* Ensures the content inside box is vertically aligned */
}

.joinva-logos .box img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
    border-radius: calc(.4rem); /* Border radius of the image, adjusted for the container's border-radius minus padding */
}
.joinva-logos .spacer {
    flex-basis: 100%;
}

.joinva-logos .box p.price {
    font-weight: 500;
    font-size: 1rem;
    padding: 0 .5rem .5rem .5rem;
}
.joinva-logos .box p.alreadyPayed {
    font-size: 0.765625rem;
    text-transform: uppercase;
    padding: 0 .5rem .1rem .5rem;
    width: 100%;
    text-align: center;
}

.joinva-logos .box p {
    padding: 1rem .5rem;
    color: #333;
    text-align: left; /* Align text to the left for better readability */
    margin: 0; /* Ensure no extra margin */
}
